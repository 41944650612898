import { environment } from './../../environments/environment';
/**
 * Academic System Base Endpoint class
 * Defined by: Aditya Zulkarnain
 * https://www.trisakti.ac.id
 * jumpalitan.jumpalitan@gmail.ac.id
 */

// Define API URL and API Version Here
/**
 * IG2 API Live
 *
 */
const API = environment.apiUrl;
const APP_CODE = environment.appCode;
const BLOB = "https://jsonblob.com/api/jsonBlob";
// const APP_CODE = 114;

/**
 * General Config
 */
// const API_VERSION = 'v1';

export class Endpoint {

  getUrl(namespace: any, key: any, context: any) {
    /*
    * Endpoint Object you can create object based on endpoint namespace
		* example the namespace of endpoint is user you can create user object on endpoint base object
		* You can create key inside endpoint namespace, key is defined as endpoint namespace key
		* example url is user/profile
		* you can create object like this
		* user : {
		*   profile:`${API}/user/profile`
		* }
    */
    const ENDPOINT = {
      oauth: {
        access_token: `${API}/issueauth`,
        user_profile: `${API}/issueprofile`,
        user_scope: `${API}/issuescope/${APP_CODE}`,
        par_profile:`${API}/d3b1b0f38e11d357db8a6ae20b09ff23?username=${context}`
      },
      app: {
        faq: `${API}/fc95f9c89cc77a721dbd4048a7d72341/${APP_CODE}/${context}`
      },
      admin_manage: {
        re_program_data:`${API}/db552dfa8d25c56ab12dfd43190f7871`,
        cr_program_data:`${API}/8cf2fe76a516529d75ee2512ea08f9f8`,
        up_program_data:`${API}/d42e4892298b2a087ae0c372d71447f8`,
        re_exam_location_data:`${API}/2a84bb2b56e1d469fdafdcf372028269`,
        cr_exam_location_data:`${API}/800d50385dcc6c8205eff5925f87d418`,
        up_exam_location_data:`${API}/abe29a206e1d0e1b97640f38ad41bbb6`,
        re_nationality:`${API}/311db1865a71b1d45aae2e58665e446b`,
        re_city:`${API}/bcefb7938abeb097cfd7d464d66d7740${context}`,
        re_questionnaire:`${API}/95cb27bc4452fb465c6af2c4dbb7fa5e${context}`,
        cr_questionnaire:`${API}/6c78ad8ae3b3d2a47c5eea454612af71`,
        up_questionnaire:`${API}/139a937af80ffb31035f9ab6e73683ce`,
        re_question_answer:`${API}/033f9cb4a5883fcd011a25e063252b88?questionare_id=${context}`,
        re_selection_path:`${API}/abb8791ca091313ec28e725afd837cd5${context}`,
        cr_selection_path:`${API}/62df509a25a36a7fa09aa8784fe988ea`,
        up_selection_path:`${API}/3d8b8da5e6223aee0fc494aff44df5f8`,
        re_selection_path_detail:`${API}/abb8791ca091313ec28e725afd837cd5?selection_path_id=${context}`,
        re_registration_step:`${API}/6a53e5d58c175ee5cd94c7f4bfcba0a4`,
        re_selection_step:`${API}/8582c2867a906b6ee1d62b371927fd1b?${context}`,
        cr_registration_step:`${API}/d230d8398f44631da31b45e3bfbdd1f3`,
        up_registration_step:`${API}/163915a3e8bac8f6d35b6e7e2df16e0e`,
        re_document_type:`${API}/f0f474110b5744a890eeb0244d9d08ca`,
        re_selection_document:`${API}/4dadc5e40fcfaeec032647979ff2862b?${context}`,
        cr_selection_document:`${API}/b0115214ddc12a00171b9b514db1e18c`,
        up_selection_document:`${API}/a32542bb9f8203e6a637f272d1c2f1a8`,
        re_mapp_selection_location:`${API}/ad8b08187d611e2738077c842a281d26`,
        re_gender:`${API}/f6c4e989599bda56b68f0c6e065cb16c`,
        re_exam_location_selection:`${API}/b5a9c8cd8aee63ca643cfe8f9c5bf14e?selection_path_id=${context}`,
        re_exam_location_selections:`${API}/b5a9c8cd8aee63ca643cfe8f9c5bf14e?${context}`,
        cr_exam_location_selection:`${API}/ad8b08187d611e2738077c842a281d26`,
        up_exam_location_selection:`${API}/0e1208d9c224a89d6074cf18950b7727`,
        re_exam_time:`${API}/86aed4758d078b0d286675251406339a?selection_path_id=${context}`,
        re_exam_times:`${API}/86aed4758d078b0d286675251406339a?${context}`,
        cr_exam_time:`${API}/666e255413c31e9d96580eafaafceacf`,
        up_exam_time:`${API}/4371af747f20438a5770ac3019572e5d`,
        re_pin_price:`${API}/88f81ce203cfe4da9a80b7904305016d?${context}`,
        cr_pin_price:`${API}/562c83b60ac1babd0f2c384d93045253`,
        up_pin_price:`${API}/9a50e998f50192181a2cd077487c48dd`,
        re_mapp_study_program:`${API}/a11a6c379cd4e02b4c82250a25688f64?${context}`,
        cr_mapp_study_program:`${API}/1bf80c10bab3decd38ffe4f185b85a8d`,
        up_mapp_study_program:`${API}/c4190f2ad22a972b3c64525fb85637ec`,
        re_study_program:`${API}/fcaeadaddfa118acdbed73107cf666ca${context}`,
        re_faculty:`${API}/b5ba5ce3e24c50d17d3f42a5c8f44a8f`,
        re_participant_list:`${API}/2b0e673f0c6756580982116ad00743fd?${context}`,
        re_participant_list_detail:`${API}/2b0e673f0c6756580982116ad00743fd?registration_number=${context}`,
        re_participant_payment_list:`${API}/93ef77e2423a3f41405953b3dc9c2558?${context}`,
        re_participant_approve:`${API}/d8b14289843a4d403a066f94af4c6758?${context}`,
        re_registration_program_study:`${API}/132eb16327d0b10c2be8cfdd8d3a1a58?registration_number=${context}`,
        re_registration_document:`${API}/a5c42eff89704bb6b184c4673d283274?registration_number=${context}`,
        re_participant_detail:`${API}/d3b1b0f38e11d357db8a6ae20b09ff23?participant_id=${context}`,
        re_payment_method:`${API}/97f82256ba49d5ef71ae1feb03865d27`,
        re_question_type:`${API}/b73d30ef631a9bc381a791d197598eb7`,
        re_question:`${API}/b9ae0cfc5a4776c44005e1835f11fb34?${context}`,
        cr_question:`${API}/c50c905f47d52177867017e5396b4ccc`,
        up_question:`${API}/bb69ca9eb9305797fbf4e474b21e08a8`,
        re_payment_status:`${API}/f7c5a72ca2b1e7f77e9a9dcb985324f5`,
        re_participant_pass:`${API}/057523cb2b7aa9847c35ca97a17e3059?${context}`,
        up_registration:`${API}/358fbdc63a944e76ca5d30d7da8b8d36`,
        cr_answer_option:`${API}/7348462df9ca84d9ba9eef29a2c69b60`,
        re_answer_option:`${API}/bdab791360317a503380bfd25d802ad1?question_id=${context}`,
        up_answer_option:`${API}/397bf81c46171e24cf2716fdf76eb7f3`,
        cr_up_registration_result:`${API}/753b351ed46ce677fb20284e1167fa83`,
        re_exam_type:`${API}/b3e6df28e22eaf19b225ae3b6eea4fe7`,
        re_category_id:`${API}/1618e83cb0431be048c62a3f91720674`,
        dwn_excel_participant_list:`${API}/6f6eb3995a95101cb9e7028d2cff06b2${context}`,
        dwn_excel_participant_payment:`${API}/6720cdea3cf8d9673bcf217dbb51a9f0${context}`,
        dwn_excel_participant_result:`${API}/f04c0e742709880dfe41f8ab85b874f7${context}`,
        re_active_status:`${API}/88eef5d8d2c8e0d8773d3bc40b91e3d6`,
        re_reporting_participant_per_path: `${API}/ce4693e82289d4a2fa874c6268ccf9bf`,
        re_reporting_participant_per_stupro:`${API}/84c89f886a2249b35a87e09de3d9a5c0`,
        re_reporting_participant_per_stupro_filters:`${API}/84c89f886a2249b35a87e09de3d9a5c0?selection_path_id=${context}`,
        re_reporting_participant_per_gender:`${API}/7830b0aa5288d27f92ffe90e7de9f86a`,
        re_reporting_participant_per_prov : `${API}/214393d4d1f4575a0770fbc4a57619ee`,
        re_reporting_participant_per_city : `${API}/f22de8332e16a9d40609802d9c5a1ae3`,
        re_reporting_participant_per_school : `${API}/fc58a64680cd6ec9b0a2f2ce279feabd`,
        re_reporting_selection_path_active: `${API}/f5478c85751a68626b8c48938d137196`,
        re_reporting_buyer_PIN:  `${API}/bdef773a1b08c6f69b17159abc321eb5`,
        re_reporting_program_active: `${API}/80ece404f78781edcd970da04668924d`,
        re_reporting_participant_per_step: `${API}/86fa72663033d6568259a6d91984cf79`,
        re_reporting_participant_per_step_filters: `${API}/86fa72663033d6568259a6d91984cf79?selection_path_id=${context}`,
        re_reporting_active_program_study:`${API}/cefa6db938d3000c83db469244aadbc6`,
        cr_insert_bulk_reg_result:`${API}/e51ba55edb29d1d98dbaf3c64b9748d4`,
        cr_export_participant_reg_excel:`${API}/a74a2f26f3793af7ffdb8f0ba3bba503`,
        re_education_last:`${API}/9987e6e7511e6adb34eba5b1d667ce9a${context}`,
        re_participant_pagination:`${API}/eec1e1868149149a1889a19ed56f0dc5${context}`,
        re_payment_pagination:`${API}/244bfa6bf8885ee2e637860fa6374981${context}`,
        re_result_pagination:`${API}/a2f9f8b8b19f9cefaf03477df54389ed${context}`,
        re_path_year:`${API}/46006c1c91334a1ac3e075bb3dae912e${context}`,
        re_category_program:`${API}/255c480f6a57d1c2ca9e6fd70c5ab3af`,
        re_selection_path_admin:`${API}/c2a949b3993c2c3951ceaa55f5882e87${context}`,
        re_study_program_all:`${API}/7fc1cd1c9bd3f74d572260881b753ab3`,
        re_mapping_path_year:`${API}/46006c1c91334a1ac3e075bb3dae912e`,
        cr_mapping_path_year:`${API}/969c19a0322f72c5af6337614be6ec3d`,
        upd_mapping_path_year:`${API}/b8f98c4dd61c25676e1a83cb5af41b5b`,
        del_mapping_path_year:`${API}/41c33d3893e13de34708aa2d6d11929d`,
        cr_export_postgraduate_excel:`${API}/d71f3f100f68d7ffc5c8a92c745b02f4`,
        re_announcement_card:`${API}/e0a0a44c69408050cd9b56c2825127a6${context}`,
        cr_announcement_card:`${API}/231466537d6840951fe1be88014c8822`,
        up_announcement_card:`${API}/65301f880c4370fb174eaab16001696c`,
        del_announcement_card:`${API}/f9d045a92c818d755f6a0df51f3eb836`,
        re_session_path_exam:`${API}/2f5a247e1045afe6ae2a8eb261de5188${context}`,
        cr_session_path_exam:`${API}/98c720c3a55736c20b0a2f47f877255e`,
        cr_raport_requirement_document:`${API}/afe300b04ed45963dbf71ef447e375fa`,
        upd_raport_requirement_document:`${API}/16fd72c78648a567b28d80b6640d8cd6`,
        re_raport_requirement_document:`${API}/57ec42bb2ae97a20af34bade3ecf7d70${context}`,
        re_utbk_requirement_document:`${API}/8de0d2f9c89eaa034e23761c4e3c43e7${context}`,
        cr_utbk_requirement_document:`${API}/2a9daee0f8ca66e57b65dcde00df6af0`,
        upd_utbk_requirement_document:`${API}/d59b2b01e7e8b6214b489729722af39d`,
        cr_mapping_path_year_intake:`${API}/0e1e7fae56095399cdb51498a2bc26cf`,
        del_mapping_path_year_intake:`${API}/896c67baa581d4fe642ea5865cddb60d`,
        get_mapping_path_year_intake:`${API}/c6551eee083c5e39e3745619b2cb84da${context}`,
        upd_mapping_path_year_intake:`${API}/896c67baa581d4fe642ea5865cddb60d`,
        re_verification_transaction_picklist: `${API}/94dcccb74f99943401494ffa960cf2b3`,
        re_passing_grade: `${API}/86d65fb4ee5e4431db1311df95197869${context}`,
        cr_passing_grade: `${API}/3137455dc66897c0237111aa602c7479`,
        upd_passing_grade: `${API}/70cd50ca733b7ba4adde27a2237e261b`,
        re_participant_score: `${API}/828e6eae32d9823a79233b165c88cfb0${context}`,
        cr_participant_passinggrade_faculty: `${API}/1fc078316646d46f6250347ecc5c2dae`,
        re_approval_participant_score: `${API}/c3d735150629dead4fe270c3076aecd0${context}`,
        cr_participant_passinggrade_university: `${API}/98746bf7c56e36333aa0df5f796dd47e`,
        cr_moodle_category: `${API}/4d3bacbc2cdf3da3ea2c8b1dcca74dc0`,
        del_moodle_category: `${API}/6a7ec7e1a36a3202e156a9b8c03f49ce`,
        re_categories_moodle: `${API}/48ee0ea2b7d287ba685587a70a4492c2${context}`,
        cr_moodle_course: `${API}/a1af3415cb75c801d5c3d5b167685584`,
        del_moodle_course: `${API}/52e5afe33159c0b5db8c8b4184f1045e`,
        re_moodle_course: `${API}/c0da0e23e0a207dbb9e5a7cb3fcc9d5a${context}`,
        re_moodle_quiz: `${API}/8537fdb163f73d2f6278b86e5b0d54ac${context}`,
        cr_moodle_quiz: `${API}/f3aa0b89eea17edd39d2c67d7fc21b88`,
        del_moodle_quiz: `${API}/50c3c9ac3389f4525f4f1e7e7e8deca1`,
        re_moodle_section: `${API}/bacb479dec1c18c4e18cdfa7695b7195${context}`,
        cr_moodle_section: `${API}/dee5a3ff5ea3394ede6c3a214b69d785`,
        cr_moodle_group: `${API}/22f3bc18e2f6181e003772798a04cfdb`,
        re_moodle_group: `${API}/d8e83416f5acf52a84c7675c49cdc609${context}`,
        re_new_student_doc_type: `${API}/1bbb4ac7342ac4bab84632ddceb5c515`,
        get_document_newstudent:`${API}/8a073f8610fd47c5e223b9f1efa7ce02${context}`,
        cr_mapping_doc_new_student: `${API}/e7c69e3c1c5a59bb290d7136ac3c534f`,
        del_mapping_doc_new_student: `${API}/733be36554a81522e5311653b86df593`,
        cr_registration_result_prodi: `${API}/aa2414b1ecbb4d79b9501827d9a9f41d`,
        up_approval_status_new_student: `${API}/e41aebef297de181311f6c3c9e0f20a1`,
        up_rejected_status_new_student: `${API}/45c6a79618d84284e54c09143ea6098a`,
        re_program_study_by_admin_faculty: `${API}/ce7534cc0a7a21b3f476d3755eb4e8de${context}`,
        get_credit_transfer_equalization: `${API}/35fa8f7cda0f700f2db358e80f8be48d${context}`,
        up_mapping_credit_transfer: `${API}/bcf171a135d9f6d82bbf9a9c4c277a5e`,
        get_program_study_passing_grade_with_selection_path: `${API}/3318a60dbe29b15bd100fdd9db8a9544${context}`,
        insert_student_id_and_email: `${API}/25382650bc7853bf5993da8e1fc032ca`,
        get_moodle_exam_grades: `${API}/d44e4f1771f7791bcbdc9e1942499962${context}`,

        // dummy
        get_participant_score:`${BLOB}/984659753678684160`,
      },
      participant: {
        register_account:`${API}/6665f8e3664599495c01ce5663fc6615`,
        send_email_conf:`${API}/61395e6d99c854c40307f867b730cddb`,
        reset_password:`${API}/4e374a7443c209ffcdcb722eb1b0e989`,
        forgot_password:`${API}/036bb5ee491f8e69b07cc76dee6cd6a9`,
        re_participant_detail:`${API}/d3b1b0f38e11d357db8a6ae20b09ff23?username=${context}`,
        up_participant_detail:`${API}/4192ae398ebb16e71474f3c19b7cb28f`,
        re_religion:`${API}/033f3365775e7758d63211e954f02ad0`,
        re_marriage_status:`${API}/d7d43680dae28149f345e1039fd63092`,
        re_country:`${API}/3bfcf56dd90250d22c8aa13055e50340`,
        re_province:`${API}/b66658287ee8c8592d582e664adb9bc0${context}`,
        re_district:`${API}/bb1e92d4a9b193df6a0d44a763e001d9${context}`,
        re_education_history:`${API}/a7e48b4e5d1261ae7ca9ced91eb8fb48?participant_id=${context}`,
        cr_education_history:`${API}/1b86408dae88bd7dd0cf5895ef53150c`,
        up_education_history:`${API}/846b36f1b9a996fef528b8878c9a808c`,
        del_education_history:`${API}/73f3e00bc1a7ea65d3a80d21159e3871`,
        re_family_parents:`${API}/c2a3fb74016bbd2b4addb99fd6ff8725?category=parent&participant_id=${context}`,
        cr_family_parents:`${API}/f96333d36f793df141ffe6ea54af4b89`,
        up_family_parents:`${API}/924f5e0bdea016b4f91abde8862d8bbb`,
        del_family_parents:`${API}/9eecd289741341678724b487584ebeda`,
        re_family_siblings:`${API}/c2a3fb74016bbd2b4addb99fd6ff8725?category=sibling&participant_id=${context}`,
        re_work_history:`${API}/e39c4ed4c38e652c7a18c3ffc2568a53?participant_id=${context}`,
        cr_work_history:`${API}/5b9793203cebc5dc1195c6f8393b5f35`,
        up_work_history:`${API}/e60a3718a1c8bcb0061d62b2bc9c9c7c`,
        del_work_history:`${API}/cd8695d5cc0ad0f4a793b24fd3591514`,
        re_personal_document:`${API}/c35a9addcec219548443a760ff290c93?participant_id=${context}`,
        cr_personal_document:`${API}/153463eed0e348e08a4dc0915f34c450`,
        up_doc_participant:`${API}/5dff2d603842a0062a0473eb75771e6a`,
        del_personal_document:`${API}/82d6185e59d19c31bb4cb2dd5063107c`,
        re_education_degree:`${API}/5f8c050ae7f6acf01e11f45a6c682801`,
        re_family_relation:`${API}/7d78938263604bf461782ec050cbde91?filter=${context}`,
        re_work_field:`${API}/d6ce10f1582c06875790be01f88cbb99`,
        re_work_type:`${API}/5c6b6e69399e91ef847504df1ccfb8d9?${context}`,
        re_income_range:`${API}/dee982f07f3d560c0a1a66be701c2440`,
        re_school:`${API}/55189ef062a1ac7df1a428421039de76?${context}`,
        re_school_major:`${API}/78d82f6e4c58dfe61767e785fbac1a16`,
        re_registration:`${API}/75d8e92f64cf9db5404b4fb332380d2b?${context}`,
        cr_study_program_reg:`${API}/2a5af9b6de8b58ed7e71a53cd2afa108`,
        up_study_program_reg:`${API}/24995f583f7c4ffacc2686a89e5b33de`,
        del_study_program_reg:`${API}/a39d449db5954e742886c429cf2fc80d`,
        cr_registration:`${API}/363a15d15c827b5cf3790c2c10debea8`,
        cr_doc_report_card:`${API}/9c8aee9d8f2291ae6229fa4e4f86aae6`,
        re_doc_report_card:`${API}/5d71cd08e7b981fde92dcf490acb93a4?${context}`,
        up_doc_report_card:`${API}/74dca8ab9ecf1639dddc78293da7edf1`,
        del_doc_report_card:`${API}/d3ad6022502b22c287bb7855f345f9a5`,
        cr_doc_certificate:`${API}/53ad22bd04733bbce6aeba677e331b6f`,
        re_doc_certificate:`${API}/a929cc86c748ecc6be54e31b84f34f54?${context}`,
        up_doc_certificate:`${API}/6f5147ac8bcec7b4e14b3eb590d0ce0b`,
        del_doc_certificate:`${API}/956b1d8eaf58c0885f1129d4ef61719e`,
        cr_doc_supporting:`${API}/64e74c9c86339bd6a2c8e9559d4c158e`,
        up_doc_supporting :`${API}/d783382ae22b371f2a54ec34627266a5`,
        re_doc_supporting:`${API}/30cbc61eed17aa4244a185e9fd689a95?${context}`,
        del_doc_supporting:`${API}/8e2db4d6a7adb12878fc9fd431d7eccd`,
        re_semester:`${API}/2ef8b0c01cfeffbfc4104b38a50f2752`,
        re_range:`${API}/ca6c918c2ab001dd08f5b712e5f8c330`,
        re_certificate_level:`${API}/4575738bd7e943d92e9556277962a68a`,
        re_certificate_type:`${API}/8e33ab177c516020ec50e2a914b79b0f`,
        re_selection_path:`${API}/abb8791ca091313ec28e725afd837cd5?${context}`,
        re_active_questionnaire:`${API}/29c6292a391c2a96dc8c1230abaaed05?${context}`,
        re_status_questionnaire:`${API}/3170ade7d7aa47b276be7a9c2c16aeda?${context}`,
        cr_registration_history:`${API}/30c8295944ed364061e26ebcc0acf908`,
        cr_answer_question:`${API}/e5cb0652bf026b308b03a8cf37f0b3cf`,
        pr_registration_card:`${API}/cb25acad45c3cdc62fafadd2aee1ca0b?${context}`,
        re_registration_history:`${API}/ad74ffb1590c4cb023313cff2cf92e56?${context}`,
        re_doc_participant_status:`${API}/e50fa5c6f3eeb4ec253542d5295d8b79${context}`,
        del_mapping_study_program:`${API}/3f390b29e0ca9b3e3f2cbeddab044a16`,
        cr_reset_password:`${API}/e52f66124cbddc9e25b5c71e0d407146/${context}`,
        re_sofId:`${API}/6f8957136d4bc35455ffa5ab91963926${context}`,
        re_pin_transaction:`${API}/7c9dadd21bc9eb965a1cf4401f61f57b?${context}`,
        re_status_pin_transaction:`${API}/a421a4cf4040e8019c9a4fc7c062cfc4?${context}`,
        cr_pin_transaction:`${API}/9671f08e951a331ac2fd674b929f4fa0${context}`,
        cr_transaction_voucher:`${API}/56d1cc5c4fdc7a499e7ea39780d60030`,
        re_transaction_voucher:`${API}/0788660f1edb8c1d8c3c70da57cd9658${context}`,
        re_exam_session:`${API}/505e025b245d0e31335bc4c46a55b04e${context}`,
        re_check_technic_major:`${API}/ac6576b6dad5af65aa17f55c24a44ce2${context}`,
        del_registration_history_steps:`${API}/8b8c94068d57a9fa5a75f31314597f0e`,
        re_generate_exam_pass_letter:`${API}/497a8022db5bf949dc7fc5f31ac51a5f${context}`,
        cr_answer_questionnaire:`${API}/66d20b3554b85241a683bb82f55f8545`,
        re_registration_doc_raport:`${API}/e3fcd7946fcd3f31eb87a5b57f441556${context}`,
        re_validate_participant_with_program_and_path:`${API}/418ba24d0bbb3eda8382b1973996ad81${context}`,
        re_document_study:`${API}/2bf86548e56eda54c2069c39eb923866${context}`,
        up_document_study:`${API}/99d2ab7af7ee427665f7938e3abe77db`,
        cr_document_study:`${API}/474062a5a092d360694ca248913f6748`,
        del_document_study:`${API}/875a2fc7a04ba930dca00534a4082755`,
        re_document_recommendation:`${API}/1f9c0a5476bc5ec2cf34ebafa0ef97db${context}`,
        cr_document_recommendation:`${API}/760c65cd11c3cba328dcf061f9b6935c`,
        up_document_recommendation:`${API}/61d4f08679156f2887d63ca01649fed2${context}`,
        del_document_recommendation:`${API}/1f9c0a5476bc5ec2cf34ebafa0ef97db${context}`,
        re_study_program_specialization:`${API}/b7c2e2974162349aa2fe73ad3bfaa44a${context}`,
        re_payment_method_check:`${API}/97f82256ba49d5ef71ae1feb03865d27${context}`,
        cr_url_recommendation:`${API}/1c3eb0991ba93fcacd2121fae6a189c4`,
        re_detail_recommendation:`${API}/64ac7abca9eb0baa48a6c1c30d59a464${context}`,
        up_document_proposal:`${API}/85117ab9e41a2ada282d76aa2863fe0f`,
        cr_document_proposal:`${API}/825cacd4554135a17046e023330e7f2e`,
        del_document_proposal:`${API}/22759fda176d48642722053e573ceb11`,
        get_postgraduate:`${API}/31d88d7ca4f5b08e0aa972455f5ace28${context}`,
        view_specialization_name:`${API}/6e0fef36dd107ad421b445283e12fb6b${context}`,
        get_class:`${API}/f19f28a72bb6369f7924e40ad6d73abe${context}`,
        get_raport_semesters:`${API}/4da06bfd7e840563b6ed17e9f45f1e3d${context}`,
        get_specialization_name:`${API}/5ae2e5ebfab5c6f0762d777097861ba0${context}`,
        get_classtype_specialization:`${API}/4f47f35ba9f42d026d8ac10498c34443${context}`,
        get_utbk_document:`${API}/fbfa598cb4c9ecebc33d25642e7fb6f4${context}`,
        cr_utbk_document:`${API}/72478fcbf6c1c8aff4c7e4677d3615c0`,
        up_utbk_document:`${API}/399653ec67cfd9659a415d5cf2dc1376`,
        cr_transcript_document:`${API}/1b39d52f313c37523b6d5675235c82c5`,
        del_transcript_document:`${API}/85904dd157162ad73df8619c93c73e4c`,
        re_transcript_document_details:`${API}/a054404f92791850755af3d138e93b57${context}`,
        re_transcript_document:`${API}/2f29f8809fea5ece3d45e2c0874f253a${context}`,
        re_average_participant_raport_card:`${API}/f9184febbc8ec3bd950d1a927b73998c${context}`,
        cr_average_participant_raport_card:`${API}/3ef20fb309d82e42de5ae59065e772bc`,
        re_study_programs_with_pin:`${API}/04397f93a36c25e5dbdbbdc2c90a51da${context}`,
        re_group_with_path_exam_detail:`${API}/bc7ea0cb05706355bf7f1bc94181b8b3${context}`,
        cr_moodle_user:`${API}/b9a1d598cbdc70a383bfe537e0506847`,
        cr_moodle_enrolment:`${API}/34f88e481bac2003b0caf50b4ca5593e`,
        cr_moodle_member:`${API}/ca07d05e4e13548d38a72b49e5eaa23c`,
        re_validate_participant_with_selection_path:`${API}/d3f5762db72958815ecb99b3805dc635${context}`,
        re_document_publication:`${API}/57e8cf91f19eb9e6efd234f131657d30${context}`,
        up_document_publication:`${API}/9e935b5fefefcf5695fdc53ebf130d08`,
        cr_document_publication:`${API}/df3531d63f065348c6ccc635d59528b1`,
        del_document_publication:`${API}/f2516b49bd03a843f84aab9dd061145d`,
        re_publication_position:`${API}/7535564b841df8f414aed41f76db7008`,
        re_publication_type:`${API}/63c549065fb22ac7a5072795c86e51ee`,
        get_participant_detail:`${API}/f693de0865769d68806672e28eed84db${context}`,
        get_new_student_data:`${API}/74ce2066a5f9c02412df03a75bc6312a${context}`,
        cr_mapping_new_student_step:`${API}/be13ba8894838a0e0a2bb6cc770bfc4d`,
        get_new_student_participant:`${API}/f7b837e27210de50fbc4ccf35fef2a10${context}`,
        up_new_student_participant:`${API}/446116ac32756d3c01dbf76fe477e896`,
        get_relationship_participant:`${API}/7e9805530793e2c1049f8e776ec57cc0${context}`,
        up_relationship_participant:`${API}/6f8652d7c6d48570215fc375c9c496a8`,
        get_newstudent_document:`${API}/e9c105f9ef538e635cf27db9e97f051e${context}`,
        up_doc_new_student_participant:`${API}/fb60a16897048316d67aa73b53af1978`,
        get_new_student_doc_status:`${API}/694d4edf468bb6827224d70a9bef0a58${context}`,
        cr_billing_new_student:`${API}/58a8f0305333de08f41aa829486e8fe5`,
        // dummydata
        get_new_student_document:`${BLOB}/1004251496346566656`,

      }
    };
    return ENDPOINT[namespace] && ENDPOINT[namespace][key];
  }
}
